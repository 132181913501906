<template>
  <master-layout pageTitle="Login" bgColor="white">

    <h1>Új jelszó</h1>
    <ion-card>
      <ion-card-content>
        <ion-item>
          <ion-label position="stacked">Új jelszó</ion-label>
          <ion-input type="password" v-model="userInfo.password"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Új jelszó megerősítése</ion-label>
          <ion-input type="password" v-model="userInfo.passwordConfirmation"></ion-input>
        </ion-item>
        <div class="error-message ion-padding" data-testid="message-area">
          {{ errorMessage }}
        </div>
      </ion-card-content>
    </ion-card>
    <ion-button expand="full" shape="round" @click="send()">Új jelszó rögzítése</ion-button>
    <ion-button expand="block" fill="transparent" color="primary" @click="$router.go(-1)">Mégse</ion-button>

  </master-layout>
</template>

<script>
import {
  IonCard,IonCardContent,IonItem,IonLabel,IonInput
} from '@ionic/vue'
import axios from 'axios'

export default {
  components: {
    IonCard, IonCardContent, IonItem, IonLabel, IonInput
  },
  data() {
    return {
      userInfo: {
        password: '',
        passwordConfirmation: ''
      },
      errorMessage: ''
    }
  },
  methods: {
    async send() {
      if (!this.userInfo.password || !this.userInfo.passwordConfirmation) {
        this.errorMessage = 'Kérjük töltsd ki az összes mezőt.'
      } else if (this.userInfo.password != this.userInfo.passwordConfirmation) {
        this.errorMessage = 'A két jelszónak egyezni kell.'
      } else {
        try {
          await axios.post(process.env.VUE_APP_STRAPI_URI + 'auth/reset-password', {
            code: this.$route.query.code, // code contained in the reset link
            password: this.userInfo.password,
            passwordConfirmation: this.userInfo.passwordConfirmation
          })
          this.errorMessage = ''
          alert('Az új jelszót rögzítettük. Lépj vissza az Országos Bortúra akalmazásba és használd a bejelentkezésnél az új jelszavad!')
          this.$router.push('/app/udvozoljuk')
        } catch (error) {
          this.errorMessage = 'Hiba történt. Kérjük küldj egy üzenetet a hello@orszagosbortura.hu e-mail címre.'
        }
      }
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 20px 40px;
}
.pMargins{
  margin: 20px 0 10px 0;
}
</style>
